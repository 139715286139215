import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const $ = require("jquery");
require("datatables.net");

export default function Drop() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [challenge, setChallenge] = useState([]);

  const Allchallenge = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`${baseUrl}challange/drop_challange`, { headers });

      if (res.data.length > 0) {
        setChallenge(res.data);
      } else {
        setChallenge([]);
      }
    } catch (error) {
      console.error("Error fetching challenges:", error);
    }
  };

  const CancelGame = async (id) => {
    if (!window.confirm("Are you sure to delete?")) return;
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      await axios.delete(`${baseUrl}dropedchallange/delete/${id}`, { headers });

      setChallenge((prevChallenges) => prevChallenges.filter((game) => game._id !== id));
    } catch (error) {
      console.error("Error deleting game:", error);
      window.alert("Sorry, try again");
    }
  };

  const deleteAlldrop = async () => {
    if (!window.confirm("Are you sure to delete all?")) return;
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      await axios.delete(`${baseUrl}dropedchallange/deleteAll`, { headers });
      setChallenge([]);
    } catch (error) {
      console.error("Error deleting all games:", error);
      window.alert("Sorry, try again");
    }
  };

  useEffect(() => {
    Allchallenge();
  }, []);

  useEffect(() => {
    if (challenge.length > 0) {
      const table = $("table").DataTable();
      return () => table.destroy();
    }
  }, [challenge]);

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">DROP CHALLENGES</h4>
              <div className="text-center my-3">
                All Drop Challenges &nbsp;
                <button
                  type="button"
                  className="btn mx-1 btn-danger"
                  onClick={deleteAlldrop}
                >
                  Delete All
                </button>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Creator</th>
                      <th>Accepter</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Game Type</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challenge.map((game, key) => (
                      <tr key={game._id}>
                        <td>{key + 1}</td>
                        <td>{game._id}</td>
                        <td>{game.Created_by?.Name || "N/A"}</td>
                        <td>{game.Accepetd_By?.Name || "N/A"}</td>
                        <td>{game.Game_Ammount}</td>
                        <td className="text-primary font-weight-bold">{game.Status}</td>
                        <td>{game.Game_type}</td>
                        <td>{dateFormat(game.createdAt).split(",")[0]}</td>
                        <td>
                          {game.Created_by && game.Accepetd_By && (
                            <Link className="btn btn-primary mx-1" to={`/view/${game._id}`}>
                              View
                            </Link>
                          )}
                          {game.Status === "drop" && (
                            <button
                              type="button"
                              className="btn mx-1 btn-danger"
                              onClick={() => CancelGame(game._id)}
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
