import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const Gateway = () => {
  const [AccountName, setAccountName] = useState("");
  const [CashFreeDeposit, setCashFreeDeposit] = useState(false);
  const [CashFreePayout, setCashFreePayout] = useState(false);
  const [ManualUpiId, setManualUpiId] = useState("");
  const [ManualDeposit, setManualDeposit] = useState(false);
  const [ManualPayout, setManualPayout] = useState(false);
  const [ManualPayoutBank, setManualPayoutBank] = useState(false);
  const [WalletFlowPayOut, setWalletFlowPayOut] = useState(false);
  const [PhonepeDeposit, setPhonepeDeposit] = useState(false);
  const [UpigatwayDeposit, setUpigatwayDeposit] = useState(false);
  const [RazorDeposit, setRazorDeposit] = useState(false);
  const [RazorPayout, setRazorpayout] = useState(false);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [decentroPayout, setdecentropayout] = useState(false);
  const [decentroDeposit, setdecentroDeposit] = useState(false);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [QrCode, setQrCode] = useState(null);
  const [settingId, setSettingId] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  useEffect(() => {
    axios
      .get(baseUrl + "gatewaysettings/data")
      .then((res) => {
        if (res.data.status === 0) {
          console.log(res.data.msg);
        } else {
          setAccountName(res.data.data.AccountName);
          setCashFreeDeposit(res.data.data.CashFreeDeposit);
          setCashFreePayout(res.data.data.CashFreePayout);
          setQrCode(res.data.data.QrCode);
          setManualUpiId(res.data.data.ManualUpiId);
          setManualDeposit(res.data.data.ManualDeposit);
          setManualPayout(res.data.data.ManualPayout);
          setManualPayoutBank(res.data.data.ManualPayoutBank);
          setWalletFlowPayOut(res.data.data.WalletFlowPayOut);
          setPhonepeDeposit(res.data.data.PhonepeDeposit);
          setUpigatwayDeposit(res.data.data.UpigatwayDeposit);
          setSettingId(res?.data?.data?._id);
          setRazorDeposit(res.data.data.RazorDeposit);
          setRazorpayout(res.data.data.RazorPayout);
          setRazorpayAuto(res.data.data.RazorpayAuto);
          setdecentroDeposit(res.data.data.decentroDeposit);
          setdecentropayout(res.data.data.decentroPayout);
          setdecentroAuto(res.data.data.decentroAuto);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const qrcode = document.getElementById("QrCode");
    qrcode.onchange = (e) => {
      const [file] = qrcode.files;
      setQrCode(file);
    };
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("AccountName", AccountName);
    formData.append("CashFreeDeposit", CashFreeDeposit);
    formData.append("CashFreePayout", CashFreePayout);
    formData.append("QrCode", QrCode);
    formData.append("ManualUpiId", ManualUpiId);
    formData.append("ManualDeposit", ManualDeposit);
    formData.append("ManualPayout", ManualPayout);
    formData.append("ManualPayoutBank", ManualPayoutBank);
    formData.append("WalletFlowPayOut", WalletFlowPayOut);
    formData.append("PhonepeDeposit", PhonepeDeposit);
    formData.append("UpigatwayDeposit", UpigatwayDeposit);
    formData.append("RazorPayout", RazorPayout);
    formData.append("RazorDeposit", RazorDeposit);
    formData.append("RazorpayAuto", RazorpayAuto);
    formData.append("decentroPayout", decentroPayout);
    formData.append("decentroDeposit", decentroDeposit);
    formData.append("decentroAuto", decentroAuto);
    formData.append("settingId", settingId);

    try {
      const response = await axios.post(baseUrl + "gatewaysettings", formData);
      if (response.data.status === "success") {
        Swal.fire({
          title: "Data updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Settings not submitted",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <h4 className="text-uppercase text-warning font-weight-bold my-3">
        Payment Gateway Settings
      </h4>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="AccountName">Account Name</label>
          <input
            className="form-control"
            type="text"
            value={AccountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </div>
      </div>

      {/* RazorPay Settings */}
      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>
      <form
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        encType="multipart/form-data"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className="col-6 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control"
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            {/* Razorpay Payout */}
            <label htmlFor="buttonrazpay" className="col-6 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control"
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            {/* Razorpay Auto */}
            <label htmlFor="buttonrazauto" className="col-6 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value === "true")}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Phonepe or Upigateway</h5>
            <label htmlFor="buttonrazdep" className="col-6 my-1">
              UPIgatway Deposite
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={UpigatwayDeposit}
              onChange={(e) => setUpigatwayDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>





            <label htmlFor="buttonphonepe" className="col-6 my-1">
              Phonepe Deposite
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={PhonepeDeposit}
              onChange={(e) => setPhonepeDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Wallet Flow</h5>
            <label htmlFor="buttoncashpay" className="col-6 my-1">
              Wallet Flow PayOut
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={WalletFlowPayOut}
              onChange={(e) => setWalletFlowPayOut(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>            
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">CashFree</h5>
            <label htmlFor="buttoncashpay" className="col-6 my-1">
              CashFree Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={CashFreeDeposit}
              onChange={(e) => setCashFreeDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttoncashpayout" className="col-6 my-1">
              CashFree Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={CashFreePayout}
              onChange={(e) => setCashFreePayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>



            
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Decentro</h5>
            <label htmlFor="buttondecpay" className="col-6 my-1"> Decentro payout </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-6 my-1">
              Decentro Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroDeposit}
              onChange={(e) => setdecentroDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-6 my-1">
              Decentro Auto
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroAuto}
              onChange={(e) => setdecentroAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Manual Payment System</h5>
            {QrCode && <div>
              <div style={{ width: "100%" }}>
                <img src={baseUrl + QrCode} style={{ width: "100%" }} alt='scrshot' />
              </div>
            </div>
            }

            <div>
              {imagePreview && (
                <div>
                  <p>Image Preview:</p>
                  <img src={imagePreview} alt="Preview" style={{ width: '300px' }} />
                </div>
              )}
              <label htmlFor="buttonmpay" className="col-6 my-1">Payment Qr Code</label>
              <input type="file" id="QrCode" name="QrCode" onChange={handleImageChange} />
            </div>
            <div>
            <label htmlFor="buttonmpay" className="col-6 my-1">
              Enter UPI_id
            </label>
            <input
              className="form-control "
              name=""
              id=""
              value={ManualUpiId}
              onChange={(e) => setManualUpiId(e.target.value)}
            />
            </div>
            
            <label htmlFor="buttonmpay" className="col-6 my-1">
              Manual Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualDeposit}
              onChange={(e) => setManualDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonmpayout" className="col-6 my-1">
              Manual Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualPayout}
              onChange={(e) => setManualPayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
            <label htmlFor="buttonmpayout" className="col-6 my-1">
              Manual Payout Bank
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualPayoutBank}
              onChange={(e) => setManualPayoutBank(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

       <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;