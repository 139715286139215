import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

function Earings() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;

    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    const [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [EARING, setEARING] = useState([]);
    const [TOTELEARING, setTOTELEARING] = useState(0);
    const [dailyEarnings, setDailyEarnings] = useState([]);

    const [values, setValues] = useState([
        new DateObject().subtract(4, "days"),
        new DateObject().add(4, "days")
    ]);

    const setpageLimit = (event) => {
        setLimit(parseInt(event.target.value));
    };

    const handlePageClick = (data) => {
        const currentPage = data.selected + 1;
        setPageNumber(currentPage);
    };

    useEffect(() => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };

        axios.get(baseUrl + `admin/Earning/total`, { headers })
            .then((res) => setTOTELEARING(res.data.total))
            .catch((err) => console.error(err));

        axios.get(`${baseUrl}admin/Earning?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`, { headers })
            .then((res) => {
                setEARING(res.data.admin);
                setNumberOfPages(res.data.totalPages);
            })
            .catch((err) => console.error(err));

        axios.get(baseUrl + `admin/Earning/daily`, { headers })
            .then((res) => setDailyEarnings(res.data))
            .catch((err) => console.error(err));
    }, [pageNumber, limit, values]);

    const monthlyTotal = dailyEarnings.reduce((acc, item) => acc + item.totalEarning, 0);

    const createDownloadData = () => {
        const table1 = [{ A: "id", B: "Amount" }];

        EARING.forEach((row) => {
            table1.push({
                A: `Earn From Challenge ${row.Earned_Form} on ${row.createdAt}`,
                B: row.Ammount,
            });
        });

        const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "AdminEarning.xlsx");
    };

    return (
        <>
            <div className="mt-3 d-flex align-items-center justify-content-between">
                <h3 className='text-light'>
                    ADMIN TOTAL EARNING : ₹{TOTELEARING?.toFixed(2)}
                </h3>
                <select className='form-control col-sm-1 bg-dark text-light' onChange={setpageLimit}>
                    <option value="10">Set limit</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
                <button onClick={createDownloadData} className="btn btn-success mx-2">
                    Export Data
                </button>
                <DatePicker value={values} onChange={setValues} range className='bg-dark text-light' />
            </div>

            {/* Challenge-wise Earnings Table */}
            <div className="row mt-4">
                <div className="col-12 grid-margin">
                    <div className="card text-white">
                        <div className="card-body text-light" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
                            <h4 className="text-center">Challenge-wise Earnings</h4>
                            <div className="table-responsive">
                                <table className="table text-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Details</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {EARING.map((earning, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>Earn From Challenge {earning.Earned_Form} on {earning.createdAt}</td>
                                                <td>₹{earning.Ammount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='mt-4 bg-dark'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={numberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Daily Earnings Graph */}
            <div className="card text-white mt-4">
                <div className="card-body text-light" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
                    <h4 className="text-center mb-4">Last 30 Days Daily Earnings Graph</h4>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={dailyEarnings}
                            margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="_id" stroke="#ffffff" />
                            <YAxis stroke="#ffffff" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="totalEarning" fill="#00ff99" name="Total Earnings" />
                            <Bar dataKey="count" fill="#3399ff" name="Transaction Count" />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="text-end mt-2">
                        <h5 className="text-success">
                            Monthly Total Earning: ₹{monthlyTotal.toFixed(2)}
                        </h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Earings;
